import Layout from "../components/layout";
import * as React from "react";
import {GatsbyImage} from "gatsby-plugin-image";
import {graphql, Link} from "gatsby";
import BlockContent from "../components/BlockContent";

const HomePage = ({ data }) => {
  const home = data.sanityHome;
  return (
    <>
      <Layout title={'Home'}>
        <section className="max-w-screen-md mx-auto mb-12">
          <GatsbyImage alt={'Hero banner'} image={home.welcomeImage.asset.gatsbyImageData}/>
          <div className={"my-6"}>
            <BlockContent blocks={home.welcomeParagraph}/>
          </div>
          <Link to={'/upload'}>
            <button className={'w-full bg-primary text-white rounded text-center text-lg px-4 py-2'}>
              {home.goToUploadButton}
            </button>
          </Link>
        </section>
      </Layout>
    </>
  )
}
export const query = graphql`
  query {
    sanityHome {
      welcomeParagraph {
        children {
          text
          _key
          _type
          marks
        }
        _type
      }
      goToUploadButton
      welcomeImage {
        asset {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  }
`


export default HomePage
